/* eslint-disable no-unused-vars */
/* eslint-disable func-names */
/* eslint-disable no-param-reassign */
document.addEventListener('DOMContentLoaded', () => {
  // Show an element
  const show = function (elem) {
    // Get the natural height of the element
    const getHeight = function () {
      elem.style.display = 'block'; // Make it visible
      const height = `${elem.scrollHeight}px`; // Get it's height
      elem.style.display = ''; //  Hide it again
      return height;
    };

    const height = getHeight(); // Get the natural height
    elem.classList.add('is-visible'); // Make the element visible
    elem.style.height = height; // Update the max-height

    // Once the transition is complete, remove the inline max-height
    // so the content can scale responsively
    window.setTimeout(() => {
      elem.style.height = '';
    }, 350);
  };

  // Hide an element
  const hide = function (elem) {
    // Give the element a height to change from
    elem.style.height = `${elem.scrollHeight}px`;

    // Set the height back to 0
    window.setTimeout(() => {
      elem.style.height = '0';
    }, 1);

    // When the transition is complete, hide it
    window.setTimeout(() => {
      elem.classList.remove('is-visible');
    }, 350);
  };

  // Toggle element visibility
  const toggle = function (elem, timing) {
    // If the element is visible, hide it
    if (elem.classList.contains('is-visible')) {
      hide(elem);
      return;
    }

    // Otherwise, hide other open elements and show the current one
    const elements = document.querySelectorAll('.header-nav__sub');

    elements.forEach((element) => {
      if (element.classList.contains('is-visible')) {
        hide(element);
        element.closest('.has-subnav').classList.remove('submenu-open');
      }
    });

    show(elem);
  };

  // Listen for click events
  document.querySelectorAll('.js-toggle').forEach((element) => {
    element.addEventListener('click', (event) => {
      const { target } = event;
      const wrapper = target.closest('.has-subnav');
      const content = wrapper.querySelector('.header-nav__sub');

      if (window.innerWidth > 767) {
        wrapper.classList.toggle('submenu-open');
        toggle(content);
      }
    });
  });
});
