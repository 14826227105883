import LazyLoad from 'vanilla-lazyload';

document.addEventListener('DOMContentLoaded', () => {
  // eslint-disable-next-line no-unused-vars
  const pageLazyLoad = new LazyLoad({
    use_native: true,
  });

  // eslint-disable-next-line no-unused-vars
  const pageBackgroundLazyLoad = new LazyLoad({});
});
