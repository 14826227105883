/* eslint-disable no-unused-vars */
import SwiperCore, { Autoplay, EffectFade } from 'swiper';

SwiperCore.use([EffectFade, Autoplay]);

// const swiper = new SwiperCore('.swiper-container', {
//   slidesPerView: 1,
//   speed: 2000,
//   loop: true,
//   autoplay: {
//     delay: 3000,
//   },
//   effect: 'fade',
//   fadeEffect: {
//     crossFade: true,
//   },
//   preventClicks: true,
//   allowTouchMove: false,
// });

const myCustomSlider = document.querySelectorAll('.swiper-container');

// eslint-disable-next-line no-plusplus
for (let i = 0; i < myCustomSlider.length; i++) {
  myCustomSlider[i].classList.add(`swiper-container-${i}`);

  const swiper = new SwiperCore(`.swiper-container-${i}`, {
    slidesPerView: 1,
    speed: 2000,
    loop: true,
    autoplay: {
      delay: 3000,
    },
    effect: 'fade',
    fadeEffect: {
      crossFade: true,
    },
    preventClicks: true,
    allowTouchMove: false,
  });
}
