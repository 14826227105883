document.addEventListener('DOMContentLoaded', () => {
  const trigger = document.querySelector('.js-menu-trigger');
  const menu = document.querySelector('.menu-group');
  const hamburger = document.querySelector('.hamburger');

  trigger.addEventListener('click', () => {
    menu.classList.toggle('is-open');
    hamburger.classList.toggle('is-open');
  });
});
