document.addEventListener('DOMContentLoaded', () => {
  document.querySelectorAll('.areas__item').forEach((element) => {
    element.addEventListener('click', (event) => {
      const areaName = event.target.getAttribute('data-target');
      const areas = document.querySelectorAll('.areas__modal');

      areas.forEach((area) => {
        const currentAreaName = area.getAttribute('data-areas-modal');

        if (currentAreaName === areaName) {
          // show
          area.classList.add('is-visible');
        } else {
          // hide
          area.classList.remove('is-visible');
        }
      });
    });
  });

  document.querySelectorAll('.areas__modal__close').forEach((element) => {
    element.addEventListener('click', (event) => {
      const modal = event.target.closest('.areas__modal');

      modal.classList.remove('is-visible');
    });
  });
});
